<template>
  <div class="page question-page">
    <QHeader />

    <img src="../../assets/images/q1.png" class="content" />

    <div class="ask">
      <img src="../../assets/images/earphone.png" class="ask-bg" />
      <div class="ask-no">
        <h4>第一题</h4>
        <p>QUESTION ONE</p>
      </div>
      <div class="ask-line"></div>
      <div class="ask-content">
        <p>和喜欢的人户外漫步，</p>
        <p>您希望是在一下什么场景？</p>
      </div>
    </div>

    <div class="items">
      <div
        class="item"
        :class="{ active: key === choice }"
        v-for="(item, key) in items"
        :key="key"
        @click="select(key)"
      >
        {{ item }}
      </div>
    </div>

    <div class="action">
      <button class="btn" @click="back">上一题</button>
      <button class="btn next" @click="next">下一题</button>
    </div>
  </div>
</template>
<script>
import QHeader from "../../components/QHeader";
import { mapState } from "vuex";

export default {
  name: "Step1",

  components: { QHeader },

  computed: {
    ...mapState({
      record: (state) => state.detail.record,
    }),
  },

  watch: {
    "$store.state.detail.record"(record) {
      this.choice = record[0];
    },
  },

  data() {
    return {
      choice: null,

      items: [
        "A. 蓝天白云下，微风轻拂",
        "B. 一望无际的海边，浪涛拍岸",
        "C. 山涧小溪边，水声潺潺",
      ],
    };
  },

  mounted() {
    this.choice = this.record[0];
    this.$store.dispatch("share/config");
  },

  methods: {
    select(key) {
      this.choice = key;
    },

    back() {
      this.$router.back();
    },

    next() {
      if (![0, 1, 2].includes(this.choice)) {
        this.$layer.msg("请点击您的选择");
        return;
      }

      this.$store.commit("record/choice", [0, this.choice]);
      this.$router.push("/step2");
    },
  },
};
</script>
<style scoped lang="less">
img {
  display: block;

  &.content {
    width: 100%;
  }
}

.ask {
  position: relative;

  .ask-bg {
    width: 80px;
    position: absolute;
    top: -15px;
    right: 0;
  }
}
</style>